import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import {Layout} from "../components/Layout";

export default function MtbForkDemperDropperOnderhoud() {
    return (
        <Layout restrictedWidth={false}
                pageTitle={"MTB fork & demper toe aan onderhoud? | Steven's Bike Service"}
                metaDescription={"Zijn de fork en demper op jouw MTB toe aan onderhoud? Wij voeren dit graag voor je uit! Steven's Bike Service - dé MTB specialist van Deventer!✓"}>

            <div className={"fixed top-20 z-0 p-0"}>
                <StaticImage className={"landscape:w-screen portrait:h-screen"}
                             src={"../images/sbs-mtb-werkplaats.jpg"} alt={"Lekker ragguh"}/>
            </div>
            <div className={"h-[65vh]"}></div>

            <div className={"bg-white z-20 w-full"}>
                <div className=" max-w-6xl mx-auto lg:content-between gap-y-6">
                    <h2 className="text-4xl font-extrabold mt-16">Mountainbike fork, demper en dropper onderhoud.</h2>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        De vering van je mountainbike maakt niet alleen een comfortabele rit mogelijk maar zorgt er
                        vooral voor dat je overal grip houdt, zelfs op ruw terrein. Maar, na verloop van tijd zal je
                        fork, demper en dropper van je fiets vervuild raken.
                        Om ervoor te zorgen dat je mountainbike soepel blijft rijden, is het belangrijk om de vering
                        regelmatig te laten controleren en onderhouden. Steven's Bike Service verzorgt het onderhoud van
                        de vering graag voor je.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Als Rockshox en Fox dealer heb ik alle kennis en kunde in huis en ik ben getraind door Fox voor
                        het
                        onderhoud. Ik inspecteer de fork, demper en/of dropper en verzorg, indien nodig, klein of groot
                        onderhoud.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Hoe vaak je fork, demper en dropper onderhoud nodig hebben hangt af van hoe vaak je
                        fiets, waar je fietst en of je alleen met mooi weer rijdt of ook als het met bakken uit de lucht
                        kom zetten.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Mijn advies is om naast het zelf schoonhouden en smeren, na het eerste jaar een
                        kleine onderhoudsbeurt uit te voeren, dit houdt in een lowerleg service voor de fork en dropper,
                        een aircan service voor de demper.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Het jaar erop is groot onderhoud aan te bevelen. Dan gaat alles uit elkaar en worden alle
                        rubbers, seals en olie vervangen.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Zelf schoonmaken en smeren van de MTB fork, demper en
                        dopper</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Door zelf je fork, demper, en dropper schoon en gesmeerd te houden blijven ze soepeler lopen en
                        voorkom je “stiction” oftewel het zwaarder in veren door inwendige wrijving.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Het smeren doe je gemakkelijk met een doek en bijvoorbeeld <a
                        href={"https://eu.muc-off.com/products/silicon-shine-500ml"}
                        className={"text-blue-600 hover:underline"}
                        target={"_blank"}>Muc off silicon shine</a>. Let op dat je niks op je remmen
                        krijgt, je remmen zullen gaan piepen er erg slecht remmen, daarom is het beter op een doek te
                        spuiten uit de buurt van je mountainbike.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Zorg dat er geen zand of ander vuil meer op de binnenpoten, ook wel stanchions of uppers genoemd
                        zit. Dit is dus ook iets wat je makkelijk na het wassen van de bike kan doen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Smeer de binnenpoten in met de doek en veer de fork, demper of dropper een paar keer in.
                        Verwijder het
                        vuil wat omhoog komt onder de seals vandaan en herhaal dit een paar keer. En klaar is Kees, dat
                        loopt weer gesmeerd!
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Lowerleg en aircan service</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Bij klein onderhoud ook wel lowerleg service genoemd, gaan de onderste poten van de fork er af
                        zodat de olie, seals en dushtwippers vervangen kunnen worden. Doe je dit niet dan zullen de
                        seals steeds minder afdichten en water en vuil in de fork laten komen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Hierdoor zullen de dust whippers vol met vuil gaan zitten en als een schuurspons de binnenpoten
                        inslijten. Ook de glijlagers zullen uitslijten waardoor er speling op de fork komt. De
                        reparaties om deze schades te herstellen zijn duur en daardoor is zeker in dit geval, voorkomen
                        beter dan genezen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Voor de dropper komt dit eigenlijk op hetzelfde neer als voor de fork. Nieuwe seal, dushtwipper
                        en olie. Een aircan service houdt in dat de aircan van de demper gaat en ook hier de seals,
                        eventuele dust whippers en olie wordt vervangen.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Groot onderhoud</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Het tweede jaar zijn de fork, demper en dropper aan groot onderhoud toe. Dit wil zeggen dat ze
                        helemaal uit elkaar gaan en alle seals, rubbers, dust whippers en olie worden vervangen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Daardoor blijven alle inwendige delen in topconditie en kun jij volgas door de bossen kunt
                        crossen zonder je zorgen te maken over je demper, fork of dropper. Groot onderhoud doe ik niet
                        zelf maar stuur de fork, demper en/of dropper dan in naar de leverancier. Zo zul je alles uit
                        jouw vering halen!
                    </p>
                </div>
            </div>
        </Layout>
    )
}
